<template>
  <div class="overlay">
    <page-title>Мій будинок</page-title>
    <div class="house">
      <div class="house__empty" v-if="items.length === 0">
        Немає фонових малюнків.
      </div>
      <label class="house__item" v-for="(item, idx) in items" :key="`house__${idx}`">
        <span class="house__bg">
          <img class="house__img" :src="getHouse(item.picture)">
            <input type="radio" class="house__inp">
        </span>
      </label>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/page-title/page-title.vue'
  import axios from 'axios'

  export default {
     data() {
      return {
        items: []
      }
    },
    components: {
      PageTitle,
    },
    metaInfo() {
      return {
        title: 'Мій будинок',
        meta: [
          { vmid: 'description', property: 'description', content: 'Мій будинок' },
          { vmid: 'og:title', property: 'og:title', content: 'Мій будинок' },
          { vmid: 'og:description', property: 'og:description', content: 'Мій будинок' },
        ],
      }
    },
     methods: {
      getHouse(pic) {
        return require('@/assets/images/house/' + pic)
      },
    },
    created() {
      axios
        .get('/api/house.json')
        .then(response => (this.items = response.data));
    }
  }
</script>

<style lang="scss">
.house {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  &__item {
    flex: 1 0 33.33333%;
    max-width: 33.33333%;
    padding: 10px;
    &:hover,
    &--active {
      .house__bg {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: 8px solid #C70552;
          z-index: 2;
        }
      }
    }
  }
  &__bg {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }
  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    position: relative;
  }
  &__inp {
    visibility: hidden;
  }
}
</style>
